import * as Yup from 'yup';
export const regexNoSpace =
  /^[a-zA-Z-\u4E00-\u9FCC-!@#$&'()\\-`.+,/\"]+( [a-zA-Z-\u4E00-\u9FCC-!@#$&'()\\-`.+,/\"]+)*$/;

export const SignupSchema = ({
  parentorStudent,
}: {
  parentorStudent: string;
}) => {
  const gradYearError = 'Grad Year should be a four digit number.';

  return Yup.object().shape({
    studentFirstName: Yup.string()
      .matches(
        regexNoSpace,
        'Name may only contain letters, commas, apostrophes, and dashes.'
      )
      .required('Required'),
    studentLastName: Yup.string()
      .matches(
        regexNoSpace,
        'Name may only contain letters, commas, apostrophes, and dashes.'
      )
      .required('Required'),
    // aidOption: Yup.number().required('Required'),
    // optInSMS: Yup.boolean().required('Required'),
    studentEmail: Yup.string()
      .when('$user', (user: any, passSchema: any) => {
        return parentorStudent === 'Parent'
          ? passSchema.required('Required')
          : passSchema.required('Required');
      })
      .notOneOf(
        [Yup.ref('parentEmail')],
        'Student email cannot be the same as the parent email'
      )
      .nullable()
      .email('Email must be a valid email')
      .test('invalid-domains', 'Please enter a valid email address', function(value) {
        return !value?.toLowerCase().slice(-4).includes('.con')
      }),
    highSchoolYear: Yup.number()
      // .min(currentYear, `Please enter value greater than ${currentYear - 1}`)
      // .max(currentYear + 10, `Please enter value less than ${currentYear + 11}`)
      .min(1000, `${gradYearError}`)
      .max(
        new Date().getFullYear() + 4,
        `Grad Year can not be greater than ${new Date().getFullYear() + 4}`
      ) // ** Students less than 13 can not join */
      .required(`${gradYearError}`)
      .nullable(),
    parentFirstName: Yup.string()
      .matches(
        regexNoSpace,
        'Name may only contain letters, commas, apostrophes, and dashes.'
      )
      .required('Required'),
    parentLastName: Yup.string()
      .matches(
        regexNoSpace,
        'Name may only contain letters, commas, apostrophes, and dashes.'
      )
      .required('Required'),
    parentPhone: Yup.string().required('Required'),
    studentPhone: Yup.string().required('Required'),
    parentEmail: Yup.string()
      .notOneOf(
        [Yup.ref('studentEmail')],
        'Parent email cannot be the same as the student email'
      )
      .required('Required')
      .nullable()
      .email('Email must be a valid email')
      .test('invalid-domains', 'Please enter a valid email address', function(value) {
        return !value?.toLowerCase().slice(-4).includes('.con')
      }),
  });
};

export const SignupMBAUserSchema = () => {
  const gradYearError = 'Grad Year should be a four digit number.';

  return Yup.object().shape({
    studentFirstName: Yup.string()
      .matches(
        regexNoSpace,
        'Name may only contain letters, commas, apostrophes, and dashes.'
      )
      .required('Required'),
    studentLastName: Yup.string()
      .matches(
        regexNoSpace,
        'Name may only contain letters, commas, apostrophes, and dashes.'
      )
      .required('Required'),
    studentEmail: Yup.string()
      .email('Email must be a valid email')
      .required('Required')
      .test('invalid-domains', 'Please enter a valid email address', function(value) {
        return !value?.toLowerCase().slice(-4).includes('.con')
      }),
    undergraduateGradYear: Yup.number()
      .min(1970, `Please enter value greater than 1970`)
      .max(
        new Date().getFullYear(),
        `Grad Year can not be greater than ${new Date().getFullYear()}`
      )
      .required(`${gradYearError}`)
      .nullable(),
    studentPhone: Yup.string().required('Required'),
    undergraduateCollege: Yup.string().required('Required'),
  });
};

export const LoginFormSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
  email: Yup.string().required('Required').nullable().email('Invalid email'),
});

export const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Please Enter your password')
    .matches(/^(?=.*[A-Za-z])/, 'MIN_ONE_CHAR')
    .matches(/^(?=.*\d)/, 'MIN_ONE_NUM')
    .min(8, 'MIN_8_CHAR'),

  confirmPassword: Yup.string().test(
    'passwords-match',
    'Passwords must match',
    function (value) {
      return this.parent.password === value;
    }
  ),
});
