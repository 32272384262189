import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import colors from '../../common/colors';
import fonts from '../../common/fonts';

import SELECT_ARROW_ICON from '../../img/select-arrow-icon.svg';
import RADIO_CHECK_ICON from '../../img/radio-checked-form.svg';
import RADIO_UNCHECK_ICON from '../../img/radio-uncheck-form.svg';

export const useStylesMeeting = makeStyles((theme: Theme) =>
  createStyles({
    calendlyInfoForm: {
      '& .MuiDialog-paper': {
        maxWidth: 915,
        width: 915,
        borderRadius: 20,
        border: '1px solid #D4D4D4',
        padding: '49px 41px 45px',
        boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

        [theme.breakpoints.down('xs')]: {
          padding: '28px 18px 36px 19px',
        },
      },
    },
    calendlyInfoFormForEmail: {
      '& .MuiDialog-paper': {
        maxWidth: 528,
        width: '100%',
        borderRadius: 20,
        border: '1px solid #D4D4D4',
        padding: '43px 44px 53px',
        boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

        [theme.breakpoints.down('xs')]: {
          padding: 16,
        },
      },
    },

    scheduleMeetingWrapper: {
      minHeight: '100vh',
      padding: '70px 16px 20px',
      background: 'linear-gradient(0deg, #2F80ED 0%, #0A2463 195.65%)',

      '& .dialogWrapper': {
        maxWidth: 865,
        margin: '0px auto',
        textAlign: 'center',

        '& .MuiTypography-body1': {
          fontFamily: fonts.INTER,
          fontWeight: 600,
          fontSize: 18,
          letterSpacing: '-0.014em',
          color: '#FFFFFF',
          margin: '4px 0px 65px',
        },
      },
    },

    scheduleMeetingBox: {
      '& .userRolebutton': {
        color: '#fff',
        width: '100%',
        display: 'block',
      },

      '& .MuiTypography-h3': {
        fontWeight: 600,
        fontSize: 24,
        color: '#272929',
        letterSpacing: '-0.019em',
        marginBottom: 20,
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .MuiTypography-body2': {
        fontWeight: 400,
        fontSize: 14,
        color: '#272929',
        letterSpacing: '-0.006em',
        fontFamily: fonts.INTER,
        marginBottom: 4,
      },

      '& .MuiInputBase-input': {
        borderRadius: 6,
        fontWeight: 400,
        fontSize: 14,
        color: '#A9A9A9',
        fontFamily: fonts.INTER,
        border: '1px solid #D4D4D4',
        letterSpacing: '-0.006em',
        padding: '11px 16px',
      },

      '& .MuiInputBase-root': {
        width: '100%',
      },

      '& .inputSpacing': {
        marginBottom: 26,
      },

      '& .MuiButton-root': {
        height: 40,
        borderRadius: 12,
        fontWeight: 700,
        letterSpacing: '-0.006em',
        fontFamily: fonts.POPPINS_FONT,
        boxShadow: 'none',
        textTransform: 'capitalize',
        margin: '0px !important',

        '& .MuiButton-label': {
          fontSize: 20,

          [theme.breakpoints.down('xs')]: {
            fontSize: 14,
          },
        },
      },

      '& .MuiButton-contained': {
        maxWidth: 207,
        width: '100%',
        height: 62,
        color: colors.WHITE_ONE,
        background: '#45CE93',
        marginTop: 40,
        padding: '8px 10px',
        border: '1px solid #45CE93',

        [theme.breakpoints.down('xs')]: {
          height: 42,
          marginTop: 19,
          maxWidth: 141,
        },
      },

      '& .calendalyCard': {
        maxidth: 361,
        minHeight: 291,
        margin: '0px auto',
        background: '#D9D9D9',
        boxShadow: 'none',
      },
    },

    calendlyCard: {
      boxShadow: 'none',
      border: 0,
      borderRadius: 0,
      backgroundColor: 'transparent',

      '& .calendly-inline-widget': {
        height: 'calc(100vh - 200px) !important',
      },

      '& .iconSaleBanner': {
        height: 16,
        width: 16,
        marginTop: -5
      },

      '& .daySaleBanner.bookNowBanner': {
        backgroundColor: '#2668C1 !important',

        // '& img': {
        //   width: 19,
        //   bottom: 2,
        //   position: 'relative',
        // },
      },

      '& .daySaleBanner': {
        padding: 12,
        textAlign: 'center',
        backgroundColor: '#52C9BB',

        "& [class*='MuiTypography-body1']": {
          fontSize: 16,
          fontWeight: 700,
          color: colors.WHITE_ONE,
          fontFamily: fonts.POPPINS_FONT,

          '& span': {
            fontWeight: 600,
          },

          '& a': {
            marginLeft: 15,
            color: colors.WHITE_ONE,
            textDecoration: 'underline',
          },
        },
      },
    },

    leadFormBox: {
      '& .MuiGrid-root': {
        maxWidth: '100%',
      },

      '& .mobileFlex': {
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      },

      '& .actionButton': {
        '&:hover': {
          color: '#FFFFFF',
          background: '#38A878',
          border: '1px solid #38A878',
          boxShadow: 'none',
        },
      },

      '& .emailAddress': {
        paddingTop: 22,

        '& .MuiInputBase-input': {
          maxWidth: 629,
          width: '100%',
          height: 40,
          lineHeight: '42px',
        },

        [theme.breakpoints.down('xs')]: {
          paddingTop: 20,
        },
      },

      '& .inputCaptionText': {
        fontWeight: 400,
        fontSize: '14px !Important',
        letterSpacing: '-0.006em',
        color: '#272929',
        fontFamily: fonts.INTER,
        marginBottom: 4,
      },

      '& .sessionList': {
        marginBottom: 46,
        padding: '0px 0px 0px 30px',

        [theme.breakpoints.down('xs')]: {
          marginBottom: 14,
          padding: '0px 0px 0px 17px',
        },

        '& li': {
          fontWeight: 400,
          fontSize: 14,
          letterSpacing: '-0.006em',
          color: '#525454',
          fontFamily: fonts.INTER,
          lineHeight: '25px',

          [theme.breakpoints.down('xs')]: {
            fontSize: 12,
            lineHeight: '20px',
          },
        },
      },

      '& .phoneInputMasterResolve': {
        '& .MuiBox-root': {
          maxWidth: '100%',
          paddingBottom: '21px !Important',
        },

        '& .PhoneInputInput': {
          fontFamily: fonts.INTER,
          border: '1px solid #D4D4D4',
        },

        '& label': {
          fontWeight: 400,
          fontSize: 12,
          letterSpacing: '-0.006em',
          color: '#272929',
          fontFamily: fonts.INTER,
        },
      },

      '& .inputSpacing': {
        marginBottom: 9,
      },

      '& .MuiInputBase-root': {
        backgroundColor: 'transparent',
      },

      '& .MuiFilledInput-underline:after, & .MuiFilledInput-underline:before': {
        display: 'none',
      },

      '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: '-0.006em',
        color: '#272929',
        fontFamily: fonts.INTER,
        transform: 'translate(0px, -15px) scale(1)',
      },

      '& .phoneNumberCaption': {
        paddingLeft: 35,
      },

      '& .dropdownLabelDisplay': {
        marginTop: 21,

        '& .MuiTypography-root.MuiTypography-body2:last-child': {
          display: 'none',
        },

        '& .MuiInputBase-root': {
          maxWidth: 338,
          width: '100%',
          backgroundSize: 10,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center right 12px',
          backgroundImage: `url(${SELECT_ARROW_ICON})`,
        },

        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#d4d4d4',
        },

        '& .MuiInputBase-input': {
          border: 0,
          paddingTop: 6,
          paddingRight: 20,
        },
      },

      '& .MuiTypography-body2': {
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: '-0.006em',
        color: '#272929',
        marginBottom: 4,
        fontFamily: fonts.INTER,
      },

      '& .MuiSelect-select.MuiSelect-select': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        fontFamily: fonts.INTER,
        paddingTop: 6,
        border: 0,
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#d4d4d4',
      },

      '& .financialAid': {
        margin: 0,
      },

      '& .MuiInputBase-input': {
        height: 32,
        borderRadius: 6,
        padding: '0px 12px',
        border: '1px solid #D4D4D4',
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        fontFamily: fonts.INTER,
        boxSizing: 'border-box',

        '&::placeholder': {
          opacity: 1,
          color: '#A9A9A9',
        },
      },

      '& .MuiTypography-h4': {
        fontWeight: 600,
        fontSize: 18,
        color: '#272929',
        letterSpacing: '-0.014em',
        fontFamily: fonts.INTER,
        paddingRight: 13,
        marginBottom: 18,

        [theme.breakpoints.down('xs')]: {
          fontSize: 14.4,
        },
      },

      '& .MuiTypography-h3': {
        paddingBottom: 15,
      },

      '& .MuiFormControlLabel-root': {
        margin: 0,
      },

      '& .MuiIconButton-root': {
        width: 20,
        height: 20,
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${RADIO_UNCHECK_ICON})`,

        '&.Mui-checked': {
          backgroundImage: `url(${RADIO_CHECK_ICON})`,
        },

        '& .MuiSvgIcon-root': {
          display: 'none',
        },
      },

      '& .MuiSelect-icon': {
        display: 'none',
      },

      '& .MuiFormControlLabel-label': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#525454',
        margin: '0px 0px 0px 8px',
        fontFamily: fonts.INTER,
        minWidth: 69,
      },

      '& .actionButtonWrapper': {
        paddingRight: 29,
        marginTop: 45,
      },
    },

    meetingSent: {
      textAlign: 'center',
      paddingTop: 182,

      [theme.breakpoints.down('xs')]: {
        paddingTop: 50,
      },

      '& img': {
        marginBottom: 34,
      },

      '& .MuiTypography-h2': {
        fontWeight: 700,
        fontSize: 46,
        color: '#ffffff',
        fontFamily: fonts.POPPINS_FONT,
        marginBottom: 20,

        [theme.breakpoints.down('xs')]: {
          fontSize: 30,
        },
      },

      '& .MuiTypography-h6': {
        fontWeight: 600,
        fontSize: 24,
        color: '#ffffff',
        letterSpacing: '-0.019em',
        fontFamily: fonts.POPPINS_FONT,
        marginBottom: 14,

        [theme.breakpoints.down('xs')]: {
          fontSize: 20,
        },
      },

      '& .MuiButton-contained': {
        height: 70,
        minWidth: 218,
        borderRadius: 15,
        fontWeight: 700,
        letterSpacing: '-0.006em',
        fontFamily: fonts.POPPINS_FONT,
        boxShadow: 'none',
        backgroundColor: '#45CE93',
        textTransform: 'capitalize',
        color: '#fff',

        [theme.breakpoints.down('xs')]: {
          height: 50,
        },

        '& .MuiButton-label': {
          fontSize: 20,

          [theme.breakpoints.down('xs')]: {
            fontSize: 16,
          },
        },
      },
    },
  })
);
